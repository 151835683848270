#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

.txtLogo {
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 10px;
}

.logo {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.ant-layout-sider {
  background-color: #fff !important;
}
.site-layout .site-layout-background {
  background: #fff;
}
.ant-progress-inner {
  background-color: #ccc;
}
